<template>
    <div class="goodsList">
        <header class="centerWidth marginAuto flex alignCenter spacebetween">
            <div class="flex alignCenter" style="font-size:16px;color:#666666;">
                <img src="@/assets/img/pay/goodsCar.png" style="width:35px;height:35px;margin-right:16px;" alt="">
                购物车
            </div>
            <div class="flex">
                <div style="width:160px;font-size:16px;color:#686868;" :class="$store.getters.step > ix ? 'currStep' : ''" class="flex alignCenter step " v-for="(im,ix) in msgList" :key="ix">
                    <div class="listBe flex alignCenter spacearound">{{ix+1}}</div>
                    {{im}}
                </div>
            </div>
        </header>
        <div class="centerWidth marginAuto">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name:'goodsList',
    data(){
        return{
            msgList:[
                '我的购物车',
                '确认订单信息',
                '订单提交成功'
            ]
        }
    },
}
</script>
<style lang="scss" scoped>
.currStep{
    color: #47D7E3!important;
    .listBe{
        background: #47D7E3!important;
        color: #fff!important;
        border-color: #47D7E3!important;
    }
    &::before{background: #47D7E3!important;;}
}
.step{
    position: relative;
    margin-left: 70px;
    &:first-child{
        &::before{display: none;}
    }
    &::before{
        content: ' ';
        display: inline-block;
        width: 46px;
        height: 1px;
        background: #D8D8D8;
        position: absolute;
        left: -70px;
    }
}
.listBe{
    width: 35px;
    height: 35px;
    border: 1px solid #B8B9C0;
    border-radius: 50%;
    margin-right: 14px;
    font-size: 16px;
    color: #B8B9C0;
}
.goodsList{
    header{
        height: 70px;
        border-top: 1px solid #edf1f2;
        border-bottom: 1px solid #edf1f2;
    }
}
</style>